h1,h2,h3,h4,h5,h6{
    font-family: Helvetica !important;
    font-weight: 700;
}
.line-orange{
    width: 100%;
    height: 2px;
    background:#f36e03;
    margin: 10px 0;
}
.btn-orange, .orange{
    width:auto;
    height:auto;
    padding: 5px 10px;
    background:#f36e03;
    border-radius: 25px;
    text-decoration:none;
    color: #ffff !important;
    text-shadow: 1px 0 #a15111, -1px 0 #a15111, 0 1px #a15111, 0 -1px #a15111, 1px 1px #a15111, -1px -1px #a15111, 1px -1px #a15111, -1px 1px #a15111;
    font-weight: bold;
    transition: all ease 300ms;
}
.btn-orange:hover, .orange:hover{
    background-color: #337ab7;
    color: #fff !important;
}
.text-orange {
    color: #f36e03;
}
.white-text {
    color: #fff;
}
.blue-text {
    color: #084a7a;
}

.trust-pilot-slider, .trust-pilot-horizontal{
    margin: 20px 0;
}

/* Scrollbar general */
::-webkit-scrollbar {
    width: 12px; /* ancho para la barra vertical */
    height: 12px; /* altura para la barra horizontal */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Color del pulgar de la barra de desplazamiento */
    border-radius: 4px; /* Bordes redondeados */
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}